import { useMutation } from "@apollo/client";
import { AlegraContact } from "alegra-node-api";
import cogoToast from "cogo-toast";
import currency from "currency.js";
import arrayMutators from "final-form-arrays";
import { ExclamationCircle, MinusCircle, PlusCircle, Star } from "heroicons-react";
import React, { Fragment, useState } from "react";
import Confetti from "react-dom-confetti";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router";
import Toggle from "react-toggle";
import { FaunaPaymentStatus, HasuraOrderStatus } from "../enums";
import { Alert, TypeOfAlert } from "../ui/Alert";
import { api, orderItemsToEstimateItems } from "../utils/api";
import {
  CUSTOMERS_WITHOUT_MINIMUM,
  CUSTOMERS_WITH_DISCOUNT,
  FLEXIBLE_PRODUCTS,
  NEW_PRODUCTS,
  REMOVED_PRODUCTS,
} from "../utils/constants";
import { CREATE_ORDER_MUTATION, UPDATE_ORDER_MUTATION } from "../utils/gqlQueries";
import { DISCOUT_PERCENTGE, PROD_IMAGES, hasDiscount } from "../utils/hardprods";

const formatMoney = (n: number) => {
  const formatted = currency(n, { formatWithSymbol: true, precision: 2 }).format();
  return formatted.slice(0, -3);
};
const getDiscount = (n: number, discount: number = DISCOUT_PERCENTGE) => n * (discount / 100 - 1) * -1;

const CONFETTI_PROPS = {
  angle: 90,
  spread: 20,
  startVelocity: 10,
  elementCount: 10,
  dragFriction: 0.12,
  duration: 1500,
  width: "5px",
  height: "5px",
  perspective: "500px",
};

interface FormOrderItem {
  qty: number;
  id: string;
  discount?: string;
}

export interface IOrderFormProps {
  initialValues?: any;
  redirectUrl?: string;
  customer?: AlegraContact;
  order?: HasuraOrder;
  isAdmin?: boolean;
}

export function OrderForm({ redirectUrl, customer, order, isAdmin }: IOrderFormProps) {
  const history = useHistory();
  const [createOrderMutation] = useMutation(CREATE_ORDER_MUTATION);
  const [updateOrderMutation] = useMutation(UPDATE_ORDER_MUTATION);
  const [updateEstimate] = api.estimates.update();
  const [sendNotification] = api.notify.send();
  const [submitting, setSubmitting] = useState(false);
  const [confetti, setConfetti] = useState(0);
  const { data: settings } = api.settings.get();
  const { data: products } = api.products.get();

  const customerHardcodedDiscount = CUSTOMERS_WITH_DISCOUNT.find((c) => c.id === customer?.id);
  const customerWithoutMinBuyRestriction = customer?.id ? CUSTOMERS_WITHOUT_MINIMUM.includes(customer?.id) : false;

  const isEdit = !!order;
  const isCreate = !!!order;

  const onSubmit = async (values: { items: FormOrderItem[]; comments: string; notes: string }) => {
    if (!isAdmin) {
      const { total } = getTotals(values.items);
      if (total < 550000) {
        const sure = window.confirm(
          `Faltan ${formatMoney(550000 - total)} para que el envío sea gratis. ¿Estás seguro que quieres continuar?`
        );
        if (!sure) {
          return false;
        }
      }
    }

    setSubmitting(true);
    if (isCreate) {
      const totals = getTotals(values.items);
      const { items, ...rest } = values;
      const data = {
        status: HasuraOrderStatus.Creado,
        client: customer,
        clientId: customer?.id,
        clientName: customer?.name,
        payment: FaunaPaymentStatus.Pagar,
        items: items?.filter((i) => i.qty > 0).map<HasuraOrderItem>((item) => ({ ...item, ...getItemTotals(item) })),
        ...rest,
        ...totals,
      };
      try {
        await createOrderMutation({ variables: { data } });
        cogoToast.success("Tu orden fue creada exitosamente");
        sendNotification(`🥳 Nueva Order!\n*${customer?.name}*\n*${formatMoney(Math.round(totals.total))}*`);
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      } catch (err) {
        console.log(err);
        return err;
      } finally {
        setSubmitting(false);
      }
    } else if (isEdit && order) {
      const { items, ...rest } = values;
      const data = {
        items: items?.filter((i) => i.qty > 0).map((item) => ({ ...item, ...getItemTotals(item) })),
        ...getTotals(values.items),
        ...rest,
      } as any;
      const id = order?.id;
      try {
        let estimate;
        if (order.estimate) {
          const items = orderItemsToEstimateItems(data.items);
          try {
            estimate = await updateEstimate({ id: order.estimate.id, data: { items, anotation: data.comments } });
            cogoToast.success("Cotizacion actualizada en alegra");
          } catch (err) {
            cogoToast.error("No se pudo actualizar cotizacion en alegra");
          }
        }
        await updateOrderMutation({ variables: { id, data: { ...data, estimate } } });
        cogoToast.success("Orden editada");
        if (redirectUrl) history.push(redirectUrl);
      } catch (err) {
        return err;
      } finally {
        setSubmitting(false);
      }
    }
  };

  const getPrice = (productId: number) => {
    if (customer) {
      const product = products?.find((item) => item.id === productId);
      const priceList = product?.price?.find(
        (list) => list.idPriceList.toString() === (customer as any).priceList.id.toString()
      );
      return priceList?.price;
    }
  };

  const getTotals = (
    items: FormOrderItem[] = []
  ): {
    totalQty: number;
    totalPrice: number;
    totalTax: number;
    total: number;
    totalDiscount: number;
    totalTaxByType: Record<string, number>;
  } => {
    const totalQty = items.reduce((acc: number, curr) => (curr.qty ? acc + Number(curr.qty) : acc), 0);
    let totalDiscount = 0;

    const itemsTotals = items.filter((i) => i.qty > 0).map(getItemTotals);

    const totalPrice = itemsTotals.reduce((acc, curr) => acc + curr.price * curr.qty, 0);
    const totalTax = itemsTotals.reduce((acc, curr) => acc + curr.tax * curr.qty, 0);
    const totalTaxByType = {} as any;
    itemsTotals.forEach((item) => {
      item.taxes.forEach((tax) => {
        if (!totalTaxByType[tax.name]) {
          totalTaxByType[tax.name] = 0;
        }
        totalTaxByType[tax.name] += tax.value * item.qty;
      });
    });
    const total = totalPrice + totalTax;

    return { totalQty, totalPrice, totalTax, total, totalDiscount, totalTaxByType };
  };

  const getItemTotals = (
    item: FormOrderItem
  ): {
    tax: number;
    subtotal: number;
    price: number;
    basePrice: number;
    qty: number;
    name: string;
    taxes: {
      name: string;
      percentage: string | number;
      value: number;
    }[];
  } => {
    const id = item.id;
    const qty = item.qty;
    const product = (products || []).find((p) => {
      return p.id === Number(id);
    });
    const basePrice = product ? Number(getPrice(product.id)) : 0;
    const discounted = Number(item.discount) > 0;
    const price = discounted ? getDiscount(basePrice, Number(item.discount)) : basePrice;
    const tax =
      product?.tax.reduce((acc, curr) => {
        const taxPercentage = Number(curr.percentage) / 100;
        return acc + price * taxPercentage;
      }, 0) || 0;
    const subtotal = product ? (price + tax) * qty : 0;

    const taxes =
      product?.tax.map((t) => {
        return {
          name: t.name || "",
          percentage: t.percentage || "0",
          value: price * (Number(t.percentage) / 100),
        };
      }) || [];

    return { tax, subtotal, price, basePrice, name: product?.name || "", qty, taxes };
  };

  const validate = (values: { items: FormOrderItem[] }) => {
    const { total } = getTotals(values.items);
    if (!isAdmin && !customerWithoutMinBuyRestriction && total < 300000) {
      return {
        minTotal: "Tu orden de compra debe ser de mínimo $300.000",
      };
    }
    if (!values?.items?.some((item) => item.qty > 0)) {
      return {
        emptyProduct: "Debes seleccionar un producto",
      };
    }
    return {};
  };

  if (!products || !settings) return <div>Cargando...</div>;

  return (
    <div>
      {customerHardcodedDiscount && (
        <div>
          <Alert type={TypeOfAlert.info} title={`Tienes un descuento único.`}>
            Tu descuento es del <strong>{customerHardcodedDiscount.discount}%</strong> en todos los productos.
          </Alert>
        </div>
      )}
      <Form
        onSubmit={onSubmit}
        validate={validate}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, values, errors, invalid }) => {
          const { totalQty, totalPrice, total, totalDiscount, totalTaxByType } = getTotals(values.items);

          return (
            <form onSubmit={handleSubmit}>
              <div className="py-4 bg-orange-200 text-sm p-4 rounded-md border border-orange-300">
                <h2 className="text-sm font-bold">
                  <span role="img" aria-label="warning">⚠️</span> Productos sin stock
                </h2>
                <p>
                  Lamentamos informarte que en este momento no tenemos disponibilidad de nuestros productos con marañón
                  <span role="img" aria-label="pleading face">🥹</span>
                  <span role="img" aria-label="praying hands">🙏</span> 
                  Esperamos tenerlos nuevamente disponibles para ti muy pronto! 
                  <span role="img" aria-label="heart hands">🫶</span>
                  <span role="img" aria-label="raising hands">🙌</span> 
                  Gracias por tú paciencia y comprensión 
                  <span role="img" aria-label="hugging face">🤗</span>
                </p>
              </div>
              <div className="py-4 hidden sm:flex items-center">
                <div className="min-w-0 flex-1 flex sm:items-center sm:justify-between text-sm text-gray-600">
                  <div className="w-4/12">Producto</div>
                  <div className="mt-4 w-1/6 text-center sm:mt-0">Cantidad</div>
                  <div className="mt-4 w-1/6 text-right sm:mt-0">Precio</div>
                  <div className="mt-4 w-1/6 text-right sm:mt-0">Impuestos</div>
                  <div className="mt-4 w-1/6 text-right sm:mt-0">Subtotal</div>
                </div>
              </div>
              <div className="rounded">
                {products
                  ?.filter((prod) => (isAdmin ? true : !REMOVED_PRODUCTS.includes(prod.id)))
                  ?.map((prod, idx) => {
                    let totals;
                    const itemVals = values?.items?.[idx];
                    if (values?.items?.[idx]) {
                      totals = getItemTotals(itemVals);
                    }

                    const isAvailable = isAdmin ? true : settings.data.products[`product-available-${prod.id}`];
                    const isLow = settings.data.products[`product-low-${prod.id}`];
                    const imageUrl =
                      PROD_IMAGES[prod.id] || (prod.images?.length ? prod.images[prod.images.length - 1]?.url : null);

                    const editItem: HasuraOrderItem | undefined = isEdit
                      ? order?.items.find((item) => Number(item.id) === Number(prod.id))
                      : undefined;
                    const discounted = editItem ? Number(editItem?.discount) > 0 : hasDiscount(prod.id);

                    const isNew = NEW_PRODUCTS.includes(prod.id);

                    let discountPercent = 0;
                    if (editItem) {
                      discountPercent = Number(editItem?.discount) || 0;
                    } else if (customerHardcodedDiscount) {
                      discountPercent = customerHardcodedDiscount.discount;
                    } else if (hasDiscount(prod.id)) {
                      discountPercent = DISCOUT_PERCENTGE;
                    }

                    return (
                      <React.Fragment key={prod.id}>
                        {prod.id === 25 && <div className="font-bold border-b py-2">Empaque Flexible</div>}
                        {prod.id === 13 && <div className="font-bold border-b py-2">Envase de Vidrio</div>}
                        <div
                          key={prod.id}
                          data-id={prod.id}
                          className={`py-2 border-b sm:border-b-0 sm:border-t flex flex-wrap sm:items-center ${
                            isAvailable ? "" : "opacity-50 pointer-events-none"
                          }`}
                        >
                          {FLEXIBLE_PRODUCTS.includes(prod.id) && (
                            <div className="rounded-full -mt-1 -ml-1 bg-purple-300 w-6 h-6 absolute flex justify-center items-center text-purple-900">
                              <svg viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                <path
                                  fillRule="evenodd"
                                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          )}
                          {imageUrl && (
                            <img
                              className="mr-3 w-16 h-16 sm:w-8 sm:h-8 object-center rounded overflow-hidden object-cover"
                              src={imageUrl}
                              alt={prod.name}
                            />
                          )}
                          <div className="flex flex-grow items-center w-1/12 flex-wrap">
                            <div className="w-full sm:w-4/12 flex items-center">
                              <div>
                                {isLow && (
                                  <div className="flex items-center">
                                    <ExclamationCircle size={15} className="text-yellow-500" />
                                    <span className="ml-1 text-xs text-yellow-800">Bajo stock</span>
                                  </div>
                                )}
                                {!!!isAvailable && (
                                  <div className="flex items-center">
                                    <ExclamationCircle size={15} className="text-red-500" />
                                    <span className="ml-1 text-xs text-red-800">No disponible</span>
                                  </div>
                                )}
                                <span className="text-sm text-gray-800 flex items-center">
                                  {discounted && <Star className="text-purple-400 mr-2" size={20} />}
                                  {prod.name}
                                </span>
                              </div>
                              <Field
                                name={`items[${idx}].discount`}
                                component="input"
                                defaultValue={Number(discountPercent).toFixed(2)}
                                className="hidden"
                                validate={(value) => (value ? undefined : "Required")}
                              ></Field>
                              <Field
                                name={`items[${idx}].id`}
                                component="input"
                                defaultValue={prod.id}
                                className="hidden"
                                validate={(value) => (value ? undefined : "Required")}
                              ></Field>
                            </div>
                            <div className="flex w-6/12 sm:w-2/12 sm:mt-0 mt-1">
                              <Field
                                name={`items[${idx}].qty`}
                                parse={(val) => Number(val)}
                                initialValue={editItem?.qty || 0}
                                render={({ input }) => {
                                  return (
                                    <>
                                      <button
                                        type="button"
                                        className="z-10"
                                        disabled={!!!isAvailable}
                                        onClick={() => (input.value > 0 ? input.onChange(input.value - 1) : null)}
                                      >
                                        <MinusCircle className="text-gray-500" />
                                      </button>
                                      <input
                                        disabled={!!!isAvailable}
                                        type="number"
                                        min="0"
                                        {...input}
                                        onChange={(e) => {
                                          input.onChange(e);
                                          setConfetti(prod.id);
                                          setTimeout(() => setConfetti(0), 100);
                                        }}
                                        inputMode="numeric"
                                        pattern="\d*"
                                        className="border text-center w-20 py-1 px-2 mx-2 rounded z-10"
                                      />
                                      <button
                                        className="z-10"
                                        disabled={!!!isAvailable}
                                        type="button"
                                        onClick={() => {
                                          input.onChange(input.value + 1);
                                          setConfetti(prod.id);
                                          setTimeout(() => setConfetti(0), 100);
                                        }}
                                      >
                                        <PlusCircle className="text-gray-500" />
                                      </button>
                                      {(discounted || isNew) && (
                                        <Confetti active={confetti === prod.id} config={CONFETTI_PROPS} />
                                      )}
                                    </>
                                  );
                                }}
                              />
                            </div>

                            {totals && (
                              <>
                                <div className="flex items-end flex-col justify-center w-6/12 sm:w-1/6 sm:mt-0 mt-4 text-right font-mono text-sm">
                                  <div className={discounted ? "text-xs line-through text-gray-600" : ""}>
                                    {itemVals?.qty > 0 ? formatMoney(totals.price * itemVals.qty) : "-"}
                                  </div>
                                  {itemVals?.qty > 0 && discounted && (
                                    <div>{formatMoney(getDiscount(totals.price, discountPercent))}</div>
                                  )}
                                </div>

                                <div className="hidden sm:flex items-end flex-col justify-center w-6/12 sm:w-1/6 sm:mt-0 mt-4 text-right font-mono text-sm">
                                  <div className={discounted ? "text-xs line-through text-gray-600" : ""}>
                                    {itemVals?.qty > 0 ? formatMoney(totals.tax) : "-"}
                                  </div>
                                  {itemVals?.qty > 0 && discounted && (
                                    <div>{formatMoney(getDiscount(totals.tax, discountPercent))}</div>
                                  )}
                                </div>

                                <div className="hidden sm:flex items-end flex-col justify-center w-6/12 sm:w-1/6 sm:mt-0 mt-4 text-right font-mono text-sm">
                                  <div className={discounted ? "text-xs line-through text-gray-600" : ""}>
                                    {itemVals?.qty > 0 ? formatMoney(totals.subtotal) : "-"}
                                  </div>
                                  {itemVals?.qty > 0 && discounted && (
                                    <div>{formatMoney(getDiscount(totals.subtotal, discountPercent))}</div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>

              {totalQty > 0 && (
                <>
                  <div className="py-4 flex items-center border-t">
                    <div className="min-w-0 flex-1 ">
                      <div className="w-4/12"></div>
                      <div className="mt-4 text-right font-mono flex">
                        <span className="text-sm text-gray-600 mr-5 flex-grow">Total Items</span>
                        {totalQty}
                      </div>
                      <div className="mt-4 text-right font-mono">
                        <span className="text-sm text-gray-600 mr-5">Subtotal</span>
                        {formatMoney(totalPrice)}
                      </div>
                      {Object.entries(totalTaxByType).map(([key, value]) => (
                        <Fragment key={key}>
                          <div className="mt-4 text-right font-mono">
                            <span className="text-sm text-gray-600 mr-5">{key}</span>
                            {formatMoney(value)}
                          </div>
                        </Fragment>
                      ))}
                      <div className="mt-4 text-right font-mono font-bold text-lg">
                        <span className="text-sm text-gray-600 mr-5">Total</span>
                        {formatMoney(total)}
                      </div>
                    </div>
                  </div>
                  {totalDiscount > 0 && (
                    <div className="py-4 flex items-center justify-end font-mono font-bold ">
                      <span className="text-sm text-gray-600 mr-5">Total Descuentos</span>
                      {formatMoney(totalDiscount)}
                    </div>
                  )}
                </>
              )}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2 mt-10" htmlFor="comments">
                  Sede
                </label>
                <Field
                  name="comments"
                  id="comments"
                  initialValue={isEdit ? order?.comments : ""}
                  className="border rounded p-2 w-full text-sm h-20"
                  placeholder="Si tu empresa tiene múltiples sedes, por favor especifícanos a cúal pertenece ésta orden de compra. P. ej.: Cedritos"
                  component="textarea"
                />
              </div>
              {isAdmin && (
                <>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="notes">
                      Anotaciones Internas
                    </label>
                    <Field
                      name="notes"
                      id="notes"
                      initialValue={isEdit ? order?.notes : ""}
                      className="border rounded p-2 w-full text-sm h-20"
                      placeholder="Notas para produccion"
                      component="textarea"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Es una reposición</label>
                    <Field
                      initialValue={isEdit ? order?.isReplacement : false}
                      name="isReplacement"
                      render={({ input }) => (
                        <Toggle
                          icons={false}
                          className="toggle-sm"
                          defaultChecked={input.value}
                          onChange={(e) => input.onChange(e.target.checked)}
                        />
                      )}
                    />
                  </div>
                </>
              )}

              <div className="mt-4">
                <Alert>
                  <h3 className="text-base font-bold mb-2">Importante!</h3>
                  <ul className="list-disc ml-5">
                    {errors.minTotal && (
                      <li>
                        Tu orden de compra debe ser <strong>mínimo $300.000 pesos (IVA incluido).</strong>
                      </li>
                    )}
                    <li>
                      Ordenes a partir de <strong>$550.000</strong> pesos (IVA incluido) tienen{" "}
                      <strong>envio gratis.</strong>
                    </li>
                    <li>
                      <strong>No realices el pago</strong> hasta que la orden de compra sea confirmada.
                    </li>
                    <li>
                      Tu orden de compra esta <strong>sujeta a disponibilidad</strong> de inventario.
                    </li>
                    <li>
                      Tiempo de producción: <strong>4 días hábiles.</strong>
                    </li>
                  </ul>
                </Alert>
              </div>

              <div className="py-4">
                <button className="btn text-base" disabled={invalid || submitting} type="submit">
                  {submitting ? "Enviando..." : isEdit ? "Guardar Cambios" : "Crear Orden"}
                </button>
              </div>

              {/* <pre style={{ fontSize: 10 }}>{JSON.stringify(values, null, 2)}</pre> */}
            </form>
          );
        }}
      />
    </div>
  );
}

export default OrderForm;
