import { gql, useQuery } from "@apollo/client";
import { X } from "heroicons-react";
import React, { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { HasuraOrderStatus, FaunaPaymentStatus } from "../../enums";
import Layout from "../../layout/Layout";
import OrderContextMenu from "../../ui/OrderContextMenu";
import OrderRow, { ORDER_ROW_PRESETS } from "../../ui/OrderRow";
import { OrderFields } from "../../utils/gqlQueries";

const PER_PAGE = 30;

const QA_ORDERS = gql`
  query QaQuery($where: orders_bool_exp!, $offset: Int = 0) {
    orders_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    orders(where: $where, limit: 30, offset: $offset order_by: { created_at: desc }) {
      ${OrderFields}
    }
  }
`;


function Orders() {
  const [offset, setOffset] = useState<number>(0);
  const [where, setWhere] = useState<any>({});
  const { data, refetch } = useQuery<HasuraOrderResponse>(QA_ORDERS, {
    variables: { where: where },
  });
  const orders = data?.orders;
  const count = data?.orders_aggregate.aggregate.count || 0;

  const pages = Math.ceil(count / 30);
  
  const fetchData = useCallback(() => {
    refetch({ where: { ...where }, offset });
  }, [offset, refetch, where]);

  const onFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.target;
    if (value) {
      setWhere((prev: any) => ({ ...prev, ...{ [name]: { _eq: value } } }));
    } else {
      setWhere({});
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Layout>
      <div className="flex text-xs justify-end mb-3 items-center">
        <div className="mr-2">Filtrar:</div>
        <div className="flex">
          <select name="status" id="" onChange={onFilterChange} className="border px-2 py-1 rounded">
            <option value="">-- Estado</option>
            {Object.values(HasuraOrderStatus).map((key) => (
              <option key={key} value={key}>
                {key}
              </option>
            ))}
          </select>
          <select name="payment" id="" onChange={onFilterChange} className="border px-2 py-1 ml-2 rounded">
            <option value="">-- Pago</option>
            {Object.values(FaunaPaymentStatus).map((key) => (
              <option key={key} value={key}>
                {key}
              </option>
            ))}
          </select>
          {(where?.status || where?.payment) && (
            <button className="btn--icon-outline ml-2" onClick={() => setWhere({})}>
              <X size={10} />
            </button>
          )}
        </div>
      </div>

      <div className="bg-white shadow sm:rounded-md">
        <ul>
          {orders?.map((order) => {
            return (
              <OrderRow
                order={order}
                key={order.id}
                contextMenu={<OrderContextMenu order={order} refetchOrders={refetch} />}
                linkResolver={(id) => `/orders/${id}`}
                {...ORDER_ROW_PRESETS.ADMIN}
              />
            );
          })}
        </ul>
      </div>
      <div className="overflow-x-auto pb-2">
        <ReactPaginate
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={({ selected }) => {
            setOffset(Math.ceil(selected * PER_PAGE));
          }}
          containerClassName={"pagination"}
        />
      </div>
    </Layout>
  );
}

export default Orders;
